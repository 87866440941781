"use client"

//FIXME: This component is not working as expected. The text is not being typed out correctly.

import React, { useState, useEffect, useRef, useCallback } from 'react';
import { motion, useInView } from 'framer-motion';

interface RetroTextProps {
  text: string;
  className?: string;
}

const TYPING_INTERVAL_MIN = 80;
const TYPING_INTERVAL_MAX = 150;
const TYPING_PAUSE_MS = 1000;
const TYPING_START_DELAY_MIN = 100;
const TYPING_START_DELAY_MAX = 500;

export const RetroText: React.FC<RetroTextProps> = ({ text, className }) => {
  const [typedText, setTypedText] = useState('');
  const [isTyping, setIsTyping] = useState(false);
  const ref = useRef(null);
  const isInView = useInView(ref);
  const typingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isTypingTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const getRandomTypingInterval = useCallback(() => 
    Math.floor(Math.random() * (TYPING_INTERVAL_MAX - TYPING_INTERVAL_MIN + 1)) + TYPING_INTERVAL_MIN,
    []
  );

  const getRandomStartDelay = useCallback(() =>
    Math.floor(Math.random() * (TYPING_START_DELAY_MAX - TYPING_START_DELAY_MIN + 1)) + TYPING_START_DELAY_MIN,
    []
  );

  const typeNextChar = useCallback(() => {
    setTypedText((prev) => {
      if (prev.length === text.length) {
        setIsTyping(false);
        return prev;
      }
      typingTimeoutRef.current = setTimeout(typeNextChar, getRandomTypingInterval());
      return text.slice(0, prev.length + 1);
    });
  }, [text, getRandomTypingInterval]);

  useEffect(() => {
    if (isInView) {
      const randomStartDelay = getRandomStartDelay();
      isTypingTimeoutRef.current = setTimeout(() => {
        setIsTyping(true);
        typingTimeoutRef.current = setTimeout(typeNextChar, randomStartDelay);
      }, TYPING_PAUSE_MS - randomStartDelay);
    }

    return () => {
      if (typingTimeoutRef.current) clearTimeout(typingTimeoutRef.current);
      if (isTypingTimeoutRef.current) clearTimeout(isTypingTimeoutRef.current);
    };
  }, [isInView, typeNextChar, getRandomStartDelay]);

  useEffect(() => {
    console.log('::RetroText - Typing interval:', getRandomTypingInterval());
    console.log('::RetroText - Is in view:', isInView);
    console.log('::RetroText - Typed text:', typedText);
  }, [isInView, typedText, getRandomTypingInterval]);

  return (
    <motion.span
      ref={ref}
      initial={{ opacity: 0 }}
      animate={{ opacity: isInView ? 1 : 0 }}
      transition={{ duration: 0.5 }}
      className={`inline-block min-h-[1em] ${className}`}
    >
      {typedText || '\u00A0'}
      {isTyping && isInView && <span className="animate-blink">|</span>}
    </motion.span>
  );
};