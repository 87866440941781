import Section from "@/components/Section";
import { RetroText } from "@/components/RetroText";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";

export const OverviewSection = () => {
  const t = useTranslations("Index");

  const cardVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const OverviewCard = ({ title, value, color }: { title: string; value: string; color: string }) => (
    <motion.div
      className={`bg-gray-800 rounded-lg p-6 shadow-lg transition-all duration-300 hover:shadow-xl hover:scale-105`}
      variants={cardVariants}
    >
      <h3 className={`text-2xl font-semibold mb-3 ${color}`}>
        <RetroText text={title} className="pixel-font" />
      </h3>
      <p className="text-lg text-gray-300">
        <RetroText text={value} className="font-mono" />
      </p>
    </motion.div>
  );

  return (
    <Section
      id="overview"
      className="mb-64 text-center max-w-screen-xl mx-auto px-4 mt-20"
    >
      <motion.div
        className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8"
        initial="hidden"
        animate="visible"
        variants={{
          visible: {
            transition: {
              staggerChildren: 0.1,
            },
          },
        }}
      >
        <OverviewCard
          title={t("overview.profession.title")}
          value={t("overview.profession.value")}
          color="text-blue-400"
        />
        <OverviewCard
          title={t("overview.experience.title")}
          value={`${new Date().getFullYear() - 2017} ${t("overview.experience.value")}`}
          color="text-green-400"
        />
        <OverviewCard
          title={t("overview.education.title")}
          value={t("overview.education.value")}
          color="text-purple-400"
        />
        <OverviewCard
          title={t("overview.languages.title")}
          value={t("overview.languages.value")}
          color="text-yellow-400"
        />
        <OverviewCard
          title={t("overview.name.title")}
          value={t("overview.name.value")}
          color="text-pink-400"
        />
        <OverviewCard
          title={t("overview.location.title")}
          value={t("overview.location.value")}
          color="text-red-400"
        />
      </motion.div>
    </Section>
  );
};
