import { ReactNode } from 'react';

interface SectionProps {
  id?: string;
  children: ReactNode;
  className?: string;
}

export default function Section({ id, children, className = 'mb-64 text-center max-w-screen-md mx-auto px-4' }: SectionProps) {
  return (
    <section id={id} className={`${className}`}>
      {children}
    </section>
  );
}

