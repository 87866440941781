"use client";

import { Suspense, lazy } from 'react';
import { motion } from 'framer-motion';
import { WelcomeSection } from "@/app/sections/welcome";
import { OverviewSection } from "@/app/sections/overview";

const LoadingFallback = ({ text }: { text: string }) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    className="flex items-center justify-center h-64 bg-gray-100 rounded-lg shadow-md"
  >
    <div className="text-center">
      <div className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full animate-spin mx-auto mb-4"></div>
      <p className="text-lg font-semibold text-gray-700">{text}</p>
    </div>
  </motion.div>
);

const SectionWrapper = ({ children, fallbackText }: { children: React.ReactNode; fallbackText: string }) => (
  <Suspense fallback={<LoadingFallback text={fallbackText} />}>
    {children}
  </Suspense>
);

const LanguagesSection = lazy(() => import("@/app/sections/languages").then(mod => ({ default: mod.LanguagesSection })));
const ProjectsSection = lazy(() => import("@/app/sections/projects").then(mod => ({ default: mod.ProjectsSection })));
const ToolsSection = lazy(() => import("@/app/sections/tools").then(mod => ({ default: mod.ToolsSection })));
const AboutSection = lazy(() => import("@/app/sections/about").then(mod => ({ default: mod.AboutSection })));

export default function Index() {
  return (
      <div>
        <SectionWrapper fallbackText="Loading Welcome Section...">
          <WelcomeSection />
        </SectionWrapper>
        <SectionWrapper fallbackText="Loading Overview Section...">
          <OverviewSection />
        </SectionWrapper>
        <SectionWrapper fallbackText="Loading Languages Section...">
          <LanguagesSection />
        </SectionWrapper>
        <SectionWrapper fallbackText="Loading Projects Section...">
          <ProjectsSection />
        </SectionWrapper>
        <SectionWrapper fallbackText="Loading Tools Section...">
          <ToolsSection />
        </SectionWrapper>
        <SectionWrapper fallbackText="Loading About Section...">
          <AboutSection />
        </SectionWrapper>
      </div>
  );
}
