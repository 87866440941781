import Section from "@/components/Section";
import { RetroText } from "@/components/RetroText";
import { useTranslations } from "next-intl";
import { motion } from "framer-motion";
import { FaHandSparkles } from "react-icons/fa";
import Image from "next/image";

export const WelcomeSection = () => {
  const t = useTranslations("Index");

  return (
    <Section id="welcome" className="text-center w-full">
      <div className="w-screen px-4 rounded-b-3xl bg-gradient-to-br from-indigo-900 via-purple-900 to-pink-900">
        <div className="max-w-screen-xl mx-auto py-12 md:py-24 flex flex-col md:flex-row items-center justify-between">
          <motion.div
            initial={{ opacity: 0, x: -50 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.8 }}
            className="w-full md:w-1/2 text-center md:text-left"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-4">
              <RetroText text={`${t("welcome")} `} className="pixel-font text-yellow-400" />
              <motion.span
                className="inline-block ml-2 md:ml-4"
                animate={{
                  rotate: [0, 20, 0],
                  transition: {
                    duration: 1.5,
                    repeat: Infinity,
                    repeatType: "reverse",
                    ease: "easeInOut",
                  },
                }}
              >
                👋
              </motion.span>
            </h1>
            <h2 className="text-3xl md:text-4xl font-semibold mb-6 md:mb-8">
              <RetroText text={`${t("welcome2")} ${t("name")}`} className="pixel-font text-cyan-400" />
            </h2>
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.3 }}
              className="text-lg md:text-xl text-gray-300 mb-6"
            >
              {t("overview.profession.value")}
            </motion.p>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.6 }}
              className="mt-6 md:mt-8"
            >
              <a href="#about" className="inline-flex items-center px-4 md:px-6 py-2 md:py-3 text-base md:text-lg font-medium text-white bg-gradient-to-r from-purple-600 to-indigo-600 rounded-full hover:from-purple-700 hover:to-indigo-700 transition-all duration-300">
                <RetroText text={t("about.title")} className="pixel-font mr-2" />
                <FaHandSparkles className="text-yellow-300" />
              </a>
            </motion.div>
          </motion.div>
          
          <motion.div
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
            className="w-full md:w-1/2 mt-8 md:mt-0"
          >
            <div className="relative w-48 h-48 md:w-64 md:h-64 mx-auto">
              <Image
                src="/imgs/da4ndo-mask.png"
                alt="Da4ndo"
                layout="fill"
                objectFit="cover"
                className="rounded-full"
              />
              <motion.div
                className="absolute inset-0 border-4 border-green-400 rounded-full shadow-[0_0_10px_5px_rgba(255,255,255,0.5)]"
                animate={{
                  scale: [1, 1.05, 1],
                  rotate: [0, 5, -5, 0],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              />
            </div>
          </motion.div>
        </div>
      </div>
    </Section>
  );
};
